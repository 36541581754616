const registerHooks = {
    beforeAjax(configs) {
        return configs;
    },

    afterPageRoute() {
        dataActive.$body.fadeIn(1000);
    },

    beforePageRoute(configs) {
        $("title").html(configs.title);
        dataActive.$body.hide();
        return configs;
    },

    onPopStateError(configs) {
        // lug.error("error happend", { configs });
    },

    onViewError(configs) {
        // lug.error("error happend", { configs });
    },

    onDefaultAjaxError(configs) {
        // lug.error("error happend", { configs });
    },
}