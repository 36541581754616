DataActive.init = function () {
    window.dataActive = new DataActive();
    window.ajax = (configs) => dataActive.ajax(configs);
    window.use = dataActive.use;
    window.view = dataActive.view;
    window.getConfig = dataActive.getConfig;
    window.fileUrl = dataActive.fileUrl;
    window.downloadFile = dataActive.downloadFile;
    window.trans = dataActive.trans;
    window.siteLanguage = dataActive.siteLanguage;
    window.currencyFormatter = dataActive.currencyFormatter;
    window.d = (...args) => { console.log.apply(null, args) }
    window.dd = (...args) => { console.log.apply(null, args); throw new Error('Stop via Dumper') }

    dataActive.$head = $(document.head);
    dataActive.$body = $(document.body);

    window.lug = dataActive.getObject('LogRegistration', dataActive);
    window.former = new formElementGenerator();
    if (!dataActive.parseLocation()) {
        return;
    }

    dataActive.loadConfigs();
    dataActive.hooks.register('beforeAjax', function (configs) {
        configs.url += configs.url.indexOf('?') > -1 ? '&' : '?';
        configs.url += 'lang=' + dataActive.location.query.lang.toLowerCase();
        configs.url += '&currency=' + getConfig('currentCurrency', getConfig('currency')).id;
        if (configs.url.indexOf(dataActive.location.marketplace) > -1 && !$.isFunction(configs.error)) {
            configs.error = dataActive.defaultAjaxError;
        }
        return configs;
    });
    dataActive.hooks.load(registerHooks)
    dataActive.$body.on('click', 'a', function (e) {
        let a = $(this);
        if (a.attr('href') === undefined) {
            return;
        }
        if (a.attr('href').match(/https?:\/\//i) !== null) {
            return;
        }
        if (a.attr('href').indexOf('#') >= 0) {
            return;
        }
        e.preventDefault();
        if (a.attr('target') == '_blank') {
            href = a.attr('href');
            href += href.indexOf('?') > -1 ? '&' : '?';
            href += 'lang=' + dataActive.location.query.lang;
            window.open(href);
            return;
        }
        dataActive.pageRoute(a.attr('href'));
    })
    dataActive.siteLanguage();
    dataActive.pageRoute(dataActive.location.address);
    dataActive.$head.append(`<link rel="icon" type="image/png" href="${fileUrl(getConfig("favicon"), 'favicon')}"/>`);
    dataActive.$head.append(view('partials/ui-configs-css'));
}

DataActive.init();
